import ErrorInfo from '@components/error/ErrorInfo';

const Error = () => {
    // return <p>{statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}</p>;
    return (
        <ErrorInfo
            title="이런! 현재 해당 페이지를 찾을 수 없습니다."
            desc={`하지만 티빙에는 더 많은 티빙만의 오리지날 콘텐츠와
                라이브, 시리즈, 영화 콘텐츠가 준비되어 있습니다.
                티빙에서 더 많은 콘텐츠를 즐겨보세요!`}
            btnStr="티빙 홈으로 가기"
            btnCallback={() => {
                window.location.href = '/';
            }}
        />
    );
};

export default Error;
