import Icon from '@img/icon-null-g.svg';
import React from 'react';
import * as Styled from './ErrorInfo.styles';
import noop from '@tving/utils/src/utils/common/noop/noop';

export type ErrorInfoProps = {
    title: string;
    desc?: string;
    subdesc?: string;
    btnStr?: string;
    btnCallback?: () => void;
    isSmall?: boolean;
    isSmallButtonHide?: boolean;
};

const ErrorInfo = ({
    title = '',
    desc = '',
    subdesc = '',
    btnStr = '',
    btnCallback = noop,
    isSmall = false,
    isSmallButtonHide = false,
}: ErrorInfoProps) => {
    return (
        <Styled.InfoPage isSmall={isSmall}>
            <Styled.Icon isSmall={isSmall}>
                <Icon viewBox="0 0 72 72" />
            </Styled.Icon>
            <Styled.Title isSmall={isSmall}>
                {title.split(/\r|\n/).map((line, idx) => {
                    return (
                        <React.Fragment
                            // eslint-disable-next-line react/no-array-index-key -- 의도적으로 idx 사용 중
                            key={idx}
                        >
                            {line}
                            <br />
                        </React.Fragment>
                    );
                })}
            </Styled.Title>
            {desc && (
                <Styled.Desc isSmall={isSmall}>
                    {desc?.split(/\r|\n/).map((line, idx) => {
                        return (
                            <React.Fragment
                                // eslint-disable-next-line react/no-array-index-key -- 의도적으로 idx 사용 중
                                key={idx}
                            >
                                <p
                                    // eslint-disable-next-line react/no-danger -- 티빙 홈페이지 오류 페이지에서 안내 메시지 출력 시 사용
                                    dangerouslySetInnerHTML={{ __html: line }}
                                />
                            </React.Fragment>
                        );
                    })}
                </Styled.Desc>
            )}
            {subdesc && (
                <Styled.SubDesc isSmall={isSmall}>
                    {subdesc?.split(/\r|\n/).map((line, idx) => {
                        return (
                            <React.Fragment
                                // eslint-disable-next-line react/no-array-index-key -- 의도적으로 idx 사용 중
                                key={idx}
                            >
                                <p
                                    // eslint-disable-next-line react/no-danger -- 티빙 홈페이지 오류 페이지에서 안내 메시지 출력 시 사용
                                    dangerouslySetInnerHTML={{ __html: line }}
                                />
                            </React.Fragment>
                        );
                    })}
                </Styled.SubDesc>
            )}
            {btnStr && (
                <Styled.Button isSmallButtonHide={isSmallButtonHide} isSmall={isSmall} type="button" onClick={() => btnCallback()}>
                    {btnStr}
                </Styled.Button>
            )}
        </Styled.InfoPage>
    );
};
export default ErrorInfo;
