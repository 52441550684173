import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { device, mq } from '@styles/device';
import { space } from 'src/styles/space';

type InfoPageProps = {
    isSmall: boolean;
};

export const InfoPage = styled.div<InfoPageProps>`
    position: relative;
    text-align: center;
    padding: 4.167rem 0;
    pointer-events: visible;

    ${mq({
        paddingLeft: space.containerSidePadding,
        paddingRight: space.containerSidePadding,
    })}

    ${({ isSmall }) => isSmall && css``}
`;

const IconSmall = css`
    padding: 0rem 0 2.167rem;
    svg {
        width: 4.583rem;
        height: 4.583rem;
        transform: scale(0.9);
    }
`;

type IconProps = {
    isSmall: boolean;
};

export const Icon = styled.div<IconProps>`
    padding: 4.126rem 0 2rem;

    @media ${device.mobile} {
        ${IconSmall}
    }
    ${({ isSmall }) =>
        isSmall &&
        css`
            ${IconSmall}
            display: none;
        `}
`;

const TitleSmall = css`
    margin: 0 0 0.5rem;
    font-size: 1.5rem;
    line-height: 1.25;
`;

type TitleProps = {
    isSmall: boolean;
};

export const Title = styled.h1<TitleProps>`
    margin: 0 0 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    letter-spacing: -0.96px;
    word-break: keep-all;
    line-height: normal;

    @media ${device.mobile} {
        ${TitleSmall}
    }
    ${({ isSmall }) => isSmall && TitleSmall}
`;

const DescSmall = css`
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
    line-height: 1.38;
    span {
        display: block;
        padding: 0.5rem 0 0;
    }
`;

type DescProps = {
    isSmall: boolean;
};

export const Desc = styled.div<DescProps>`
    margin: 0.833rem 0 0;
    font-size: 1.667rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.64px;
    letter-spacing: normal;
    text-align: center;
    color: #a3a3a3;
    word-break: keep-all;
    span {
        display: block;
        padding: 1.5rem 0 0;
    }

    @media ${device.mobile} {
        ${DescSmall}
    }
    ${({ isSmall }) => isSmall && DescSmall}
`;

const SubDescSmall = css`
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
    line-height: 1.38;
    span {
        display: block;
        padding: 0.5rem 0 0;
    }
`;

type SubDescProps = {
    isSmall: boolean;
};

export const SubDesc = styled.div<SubDescProps>`
    margin: 0.833rem 0 0;
    font-size: 1.333rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.64px;
    letter-spacing: normal;
    text-align: center;
    color: #a3a3a3;
    word-break: keep-all;
    span {
        display: block;
        padding: 1.5rem 0 0;
    }

    @media ${device.mobile} {
        ${SubDescSmall}
    }
    ${({ isSmall }) => isSmall && SubDescSmall}
`;

const ButtonSmall = css`
    /* font-size: 2rem; */
    margin: 1.5rem auto 0;
    padding: 1.167rem 0;
    font-size: 1.167rem;
    width: 100%;
    text-align: center;
`;

type ButtonProps = {
    isSmall: boolean;
    isSmallButtonHide: boolean;
};

export const Button = styled.button<ButtonProps>`
    display: inline-block;
    margin: 2rem auto 0;
    padding: 1.333rem 15.167rem 1.5rem;
    border-radius: 3px;
    background-color: #dedede;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    color: #000;

    @media ${device.mobile} {
        ${ButtonSmall}
    }
    ${({ isSmall }) => isSmall && ButtonSmall}
    ${({ isSmall, isSmallButtonHide }) =>
        isSmall &&
        isSmallButtonHide &&
        css`
            display: none;
        `}
`;
